<template>
  <div>
    <v-card flat>
      <div class="px-5">
        <v-row class="my-0">
          <v-col class="d-flex align-center pb-1" cols=10>
            <span class="text-capitalize mr-1 title"> {{title}}</span>
          </v-col>
          <v-col class="d-flex justify-end pb-1 pr-1">
            <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <div class="form-body">
      <v-card-text class="pa-0 pt-2">
          <v-row class="d-flex align-center ma-0 px-5">
            <v-col cols="12" md="12" class="pa-0">
              <BaseInput
                solo
                class="text-input pa-1 pb-3"
                inputLabel="Content Plan Name"
                placeholder
                name="contentName"
                v-model.trim="contentKitPlan.name"
                :status="errors('name').length ? 'error': 'normal'"
                :error-messages="errors('name')"
                @input="$v.contentKitPlan.name.$touch()"
                @blur="$v.contentKitPlan.name.$touch()"
              />
              <BaseTextAreaField
                solo
                class="text-input mb-n4 pa-1 pt-1"
                inputLabel="Content Plan Description"
                placeholder
                rows="3"
                name="bio"
                v-model.trim="contentKitPlan.description"
                :status="errors('description').length ? 'error': 'normal'"
                :error-messages="errors('description')"
                @input="$v.contentKitPlan.description.$touch()"
                @blur="$v.contentKitPlan.description.$touch()"
              />
              <BaseInput
                solo
                name="'totalCredits'"
                class="text-input mb-2 pa-1"
                inputLabel="Total Credits"
                placeholder
                maxlength="3"
                v-model.trim="contentKitPlan.totalCredits"
                :status="errors('totalCredits').length ? 'error': 'normal'"
                :error-messages="errors('totalCredits')"
                @input="$v.contentKitPlan.totalCredits.$touch()"
                @blur="$v.contentKitPlan.totalCredits.$touch()"
              />
              <BaseInput
                solo
                name="'extraCredits'"
                class="text-input mb-2 pa-1"
                inputLabel="Extra Credits"
                placeholder
                maxlength="3"
                v-model.trim="contentKitPlan.extraCredits"
                :status="errors('extraCredits').length ? 'error': 'normal'"
                :error-messages="errors('extraCredits')"
                @input="$v.contentKitPlan.extraCredits.$touch()"
                @blur="$v.contentKitPlan.extraCredits.$touch()"
              />
              <v-col class="pa-0" v-if="contentKitPlan.extraCredits > 0">
                <BaseTextAreaField
                  solo
                  class="text-input mb-n4 pa-1 pt-0"
                  inputLabel="Notes"
                  placeholder
                  rows="2"
                  name="name"
                  v-model.trim="contentKitPlan.notes"
                  :status="errors('notes').length ? 'error': 'normal'"
                  :error-messages="errors('notes')"
                  @input="$v.contentKitPlan.notes.$touch()"
                  @blur="$v.contentKitPlan.notes.$touch()"
                />
              </v-col>
              <!-- <label class="text-left input-field-label pa-1">Business Vertical</label> -->
              <!-- <v-select
                class="text-input mb-n5 pa-1 pb-0 notranslate"
                label="Select your business vertical"
                :items="businessTypes"
                item-text="name"
                item-value="id"
                solo
                flat
                v-model="contentKitPlan.businessType"
                @change="refresh"
                :status="errors('businessType').length ? 'error': 'normal'"
                :error-messages="errors('businessType')"
                @input="$v.contentKitPlan.businessType.$touch()"
                @blur="$v.contentKitPlan.businessType.$touch()"
                :menu-props="{'content-class' : 'notranslate'}"
              ></v-select> -->
              <!-- <v-col cols="12" class="pa-0 pt-2">
                <AutoCompleteField
                  solo
                  flat
                  class="text-input mb-n5 pa-1 py-0"
                  inputLabel="Subscription"
                  :items="subscriptionOptions(contentKitPlan.businessType)"
                  name="subscription"
                  item-text="name"
                  item-value="id"
                  v-model="contentKitPlan.subscription"
                  return-object
                   notClearable
                  :status="errors('subscription').length ? 'error': 'normal'"
                  :error-messages="errors('subscription')"
                  @input="$v.contentKitPlan.subscription.$touch()"
                  @blur="$v.contentKitPlan.subscription.$touch()"
                />
              </v-col> -->
              <v-col cols="12" class="pa-0 pt-3">
                <BaseInput
                    solo
                    class="text-input disabled mb-n1 pb-3 pa-1 pt-0"
                    inputLabel="Current Capture"
                    placeholder
                    name="contentName"
                    v-model="access"
                    disabled
                  />
              </v-col>
              <CustomComboBox
                solo
                dense
                flat
                multiple
                class=" text-input mb-3 pa-1 pt-0"
                label="Suggested Platforms"
                placeholder
                :items="socialTags"
                item-text="name"
                return-object
                name="suggestedPlatform"
                hide-details
                auto-grow
                v-model="contentKitPlan.platformSuggestions"
              />
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pa-7 pr-5 mobile-button-padding">
        <v-btn
          text
          elevation="0"
          class="pa-2 draft-btn cancel"
          @click="closeForm"
        >
          CANCEL
        </v-btn>
        <v-btn class="pa-2 ml-3"
          :loading="loading"
          color="info"
          @click="submit(contentKitPlan.isDraft === true ? true : false)">
          SAVE
        </v-btn>
      </v-card-actions>
      </div>
    </v-card>
    <template v-if="showConfirm">
      <v-dialog v-model="showConfirm" max-width="600">
        <v-card class="py-4" flat>
          <v-card-title class="black--text">
            Total credits should be more than credits used.
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="info"
              @click="showConfirm = false"
              class="py-0"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import {
  maxLength, required, minValue, numeric, requiredIf,
} from 'vuelidate/lib/validators';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import CustomComboBox from '@/components/common/CustomComboBox';
import BaseInput from '@/components/common/BaseInput';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';
// import AutoCompleteField from '@/components/common/AutoCompleteField';

export default {
  components: {
    CustomComboBox,
    BaseInput,
    BaseTextAreaField,
    // AutoCompleteField,
  },
  props: {
    kit: {
      type: Object,
      default: () => {},
    },
    projectSubscriptionBlend: {
      type: Object,
      default: () => {},
    },
    projectId: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      showConfirm: false,
      contentKitPlan: {
        name: '',
        description: '',
        notes: '',
        date: '',
        totalCredits: 0,
        extraCredits: 0,
        projectId: '',
        id: null,
        creditsUsed: 0,
        isDraft: true,
        businessType: null,
        subscriptionBlendId: null,
        subscriptionBlend: {},
        subscription: {},
        project: {},
        platformSuggestions: [],
      },
      businessTypes: [],
    };
  },
  computed: {
    ...mapGetters('contentKit', ['socialTags']),
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.contentKitPlan[field].$dirty) return errors;
        switch (field) {
          case 'name':
            if (!this.$v.contentKitPlan.name.required) { errors.push('Please provide Content plan name'); }
            if (!this.$v.contentKitPlan.name.maxLength) { errors.push('Content plan name must be less than 50 characters length'); }
            break;
          case 'description':
            if (!this.$v.contentKitPlan.description.maxLength) {
              errors.push('Description length must be less than 340 characters');
            }
            break;
          case 'notes':
            if (!this.$v.contentKitPlan.notes.required) { errors.push('Please provide notes'); }
            if (!this.$v.contentKitPlan.notes.maxLength) {
              errors.push('Notes length must be less than 340 characters');
            }
            break;
          case 'businessType':
            if (!this.$v.contentKitPlan.businessType.required) {
              errors.push('Please provide your business vertical');
            }
            break;
          case 'subscription':
            if (!this.$v.contentKitPlan.subscription.required) {
              errors.push('Please provide your subscription');
            }
            break;
          case 'totalCredits':
            if (!this.$v.contentKitPlan.totalCredits.required) { errors.push('Please provide total credits'); }
            if (!this.$v.contentKitPlan.totalCredits.numeric) { errors.push('Please provide numeric value'); }
            if (!this.$v.contentKitPlan.totalCredits.minValue) { errors.push('Please provide valid total credits'); }
            break;
          case 'extraCredits':
            if (!this.$v.contentKitPlan.extraCredits.numeric) { errors.push('Please provide numeric value'); }
            if (!this.$v.contentKitPlan.extraCredits.minValue) { errors.push('Please provide valid extra credits'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    contentKitId() {
      return this.$route.params.contentKitId || (this.kit && this.kit.id);
    },
    title() {
      return this.contentKitId ? 'Edit Content Plan' : 'Add Content Plan';
    },
    totalCredit() {
      const total = Number(this.$v.contentKitPlan.totalCredits.$model)
      + Number(this.$v.contentKitPlan.extraCredits.$model);
      return total;
    },
    subscriptionOptions() {
      return (blend) => _.filter(
        this.subscriptionBlends, (id) => id.verticalId === blend,
      );
    },
    access() {
      if (this.contentKitPlan.subscription !== '') {
        return this.contentKitPlan.subscription.access;
      }
      return [];
    },
  },
  methods: {
    ...mapActions('contentKit', ['addContentKit', 'updateContentKit', 'getContentKit', 'getSocialTags']),
    ...mapActions('project', ['getSubscriptionBlends']),
    ...mapActions('profile', ['getBusinessTypes']),
    closeForm(reload) {
      this.$emit('close-modal', reload);
    },
    refresh() {
      this.contentKitPlan.subscription = '';
    },
    async submit(draftBool) {
      this.loading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.contentKitPlan.creditsUsed === undefined
          || this.contentKitPlan.creditsUsed === null
          || this.totalCredit >= this.contentKitPlan.creditsUsed) {
          let result;
          if (this.contentKitId) {
            const beforeEdit = _.map(this.kit.platformSuggestions, 'id');
            const afterEdit = _.map(this.contentKitPlan.platformSuggestions, 'id');
            let payloadObj = {};
            if (_.isEqual(beforeEdit, afterEdit)) {
              payloadObj = (Object.assign({}, { ...this.contentKitPlan },
                {
                  projectId: this.projectId || this.$route.params.projectId,
                  isDraft: draftBool,
                  subscriptionBlendId: this.contentKitPlan.subscription.id,
                }));
            } else {
              payloadObj = (Object.assign({}, { ...this.contentKitPlan },
                {
                  projectId: this.projectId || this.$route.params.projectId,
                  isDraft: draftBool,
                  suggestedPlatforms: _.map(this.contentKitPlan.platformSuggestions, 'id'),
                  subscriptionBlendId: this.contentKitPlan.subscription.id,
                }));
            }
            result = await this.updateContentKit({
              id: this.contentKitId,
              payload: payloadObj,
            });
          } else {
            result = await this.addContentKit(Object.assign({}, { ...this.contentKitPlan },
              {
                projectId: this.projectId || this.$route.params.projectId,
                date: new Date().toISOString().substr(0, 10),
                isDraft: draftBool,
                subscriptionBlendId: this.contentKitPlan.subscription.id,
                suggestedPlatforms: _.map(this.contentKitPlan.platformSuggestions, 'id'),
              }));
          }
          if (result.success) {
            this.$emit('close-modal', true);
          }
        } else {
          this.showConfirm = true;
        }
      }
      this.loading = false;
    },
  },
  validations: {
    contentKitPlan: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      description: {
        maxLength: maxLength(340),
      },
      notes: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.contentKitPlan.extraCredits;
        }),
        maxLength: maxLength(340),
      },
      totalCredits: {
        required,
        numeric,
        minValue: minValue(1),
      },
      extraCredits: {
        numeric,
        minValue: minValue(0),
      },
      // businessType: {
      //   required,
      // },
      // subscription: {
      //   required,
      // },
    },
  },
  async mounted() {
    this.getSocialTags();
    const result = await this.getSubscriptionBlends();
    this.subscriptionBlends = result.subscriptionBlends;
    const res = await this.getBusinessTypes();
    this.businessTypes = res;
    if (this.contentKitId) {
      this.contentKitPlan = Object.assign(this.contentKitPlan, this.kit);
      if (this.contentKitPlan.subscriptionBlendId !== null) {
        const blend = Object.assign({}, this.contentKitPlan.subscriptionBlend);
        this.contentKitPlan.businessType = blend.verticalId;
        this.contentKitPlan.subscription = blend;
      } else {
        const project = _.get(this.contentKitPlan, 'project', {});
        const blend = Object.assign(
          this.contentKitPlan.subscription, project.business.subscriptionBlends[0],
        );
        this.contentKitPlan.businessType = blend.verticalId;
        this.contentKitPlan.subscription = blend;
      }
    } else {
      const blend = this.projectSubscriptionBlend.subscriptionBlend;
      this.contentKitPlan.businessType = blend.verticalId;
      this.contentKitPlan.subscription = blend;
      this.contentKitPlan.totalCredits = this.projectSubscriptionBlend.totalCredits === null
        ? 0 : this.projectSubscriptionBlend.totalCredits;
    }
  },
};
</script>

<style scoped lang="scss">
  .v-card {
    border-radius: 0 !important;
  }
  .title {
    font-family: $fontFamily1 !important;
    font-size: 14px !important;
    font-weight: bold;
    color: $neutral1;
  }
  ::v-deep .input-field-label {
    color: $neutral1 !important;
  }
  ::v-deep .input-field {
    height: 40px;
    &.v-textarea {
      height: auto;
    }
  }
  .header {
    position: relative;
    z-index: 2;
  }
 .form-body {
    max-height: 540px;
    overflow: auto;
  }
  ::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px !important;
    height: auto !important;
  }
  ::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    padding-left: 6px;
    font-family: $fontFamily1;
    font-size: 14px;
    font-weight: 500;
    color: #292929;
  }
  ::v-deep .v-text-field.v-text-field--solo .v-label {
    font-size: 14px;
    padding-left: 6px;
  }
  ::v-deep .v-input__slot {
    margin-bottom: 4px;
  }
  ::v-deep.text-input > .input-field > .v-input__control > .v-input__slot {
  box-shadow: none;
  border-radius: 2px;
  border: solid 1px $silver;
  background-color: #ffffff;
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 500;
  padding-left: 0px !important;
  color: #292929;
}
.disabled {
  cursor: not-allowed;
}
::v-deep {
  .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
   margin: 4px 0 !important;
  }
}
::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
> .v-input__control > .v-input__slot {
  padding: 0px !important;
}
::v-deep .input-field-label {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.02px;
  color: $neutral1;
}
::v-deep .v-select__selections {
  padding-left: 6px;
  font-size: 14px;
}
::v-deep .v-select__slot {
  min-width: 240px;
  border: 1px solid $silver;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
  padding-left: 0px;
  min-height: 40px
}
.submit {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.save-draft {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.underline {
  text-decoration: underline;
}
.cancel-btn {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9b9b9b;
}
@media (max-width: 960px) {
  .save-draft {
    font-size: 14px !important;
    padding: 0px;
  }
  .draft-btn {
    width: 95px;
  }
  .cancel {
    width: 70px;
  }
  .mobile-button-padding {
    padding: 20px !important;
    padding-top: 8px !important;
  }
}
</style>

<template>
  <v-card class="b-1 order-summary" elevation="0">
    <v-card-title class="card-title pb-0 pl-5 pt-2 mobile-pl-2">{{name}}</v-card-title>
    <v-card-text class="d-flex justify-space-between p-1 pl-6 pr-0 py-1 pb-3">
        <v-col cols="7" class="pa-0 pl-2">
          <v-list-item two-line class="pa-0">
            <v-list-item-content class="pa-0 pb-3">
              <v-list-item-title class="order-title">
                Subscription:
              </v-list-item-title>
              <v-list-item-subtitle class="plan-text notranslate" v-if="contentKitId">
                {{ kit && kit.subscriptionBlend !== null ? kit.subscriptionBlend.name
                : subscription.name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="plan-text notranslate" v-else>
                {{ projectSubscriptionBlend
                && projectSubscriptionBlend.subscriptionBlend
                && projectSubscriptionBlend.subscriptionBlend.name}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line class="pa-0">
            <v-list-item-content class="pa-0 pb-3">
              <v-list-item-title class="order-title">
                Your current capture is:
              </v-list-item-title>
              <v-list-item-subtitle class="order-text" v-if="contentKitId">
                {{ kit && kit.subscriptionBlendId !== null ? kit.subscriptionBlend.access
                : subscription.access }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="order-text" v-else>
                  {{ getBlendAccess }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line class="suggest-height pa-0">
          <v-list-item-content class="pa-0 pb-2">
           <v-list-item-title class="suggest-title">
              <span>Production Credits:</span>
            </v-list-item-title>
              <template v-if="!editCredit">
                <v-list-item-subtitle class="suggest-text pa-0" v-if="contentKitId">
                  <span class="order-text">
                    {{ kit && kit.totalCredits === null ? 0 : kit.totalCredits }}/Content Kits
                  </span>
                  <span class="credits-text pl-2"
                  v-if="contentKitId && (kit && kit.extraCredits > 0)">
                    +{{kit.extraCredits}} extra credits
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="suggest-text pa-0" v-else>
                  <span class="order-text">
                    {{projectSubscriptionBlend.totalCredits === null ? 0
                    : projectSubscriptionBlend.totalCredits}}/Content Kits
                  </span>
                </v-list-item-subtitle>
              </template>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="5" class="pa-0">
          <v-list-item two-line class="suggest-height">
            <v-list-item-content class="pa-0">
              <v-list-item-title class="suggest-title">
                <v-row>
                  <v-col Cols="12" class="pa-0 pl-3 name-ellipsis">
                    Suggested Platforms:
                  </v-col>
                </v-row>
              </v-list-item-title>
              <template v-if="!editPlatforms">
                <v-list-item-subtitle
                  v-for="(item,index) in platforms" :key="item.title"
                  class="suggest-text pa-0">
                    <span v-if="index <= 3" class="name-ellipsis">
                      {{ item.name}}
                    </span>
                    <span
                      v-if="index === 4"
                      class="more-text mt-1"
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            View more
                          </span>
                        </template>
                        <v-col class="py-0 tool"
                          cols=12 v-for="item in platforms"
                          :key="item.title"
                        >
                          {{item.name}}
                        </v-col>
                      </v-tooltip>
                    </span>
                  </v-list-item-subtitle>
                </template>
              </v-list-item-content>
            </v-list-item>
          </v-col>
      </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'OrderSummary',
  props: {
    fromBusiness: {
      type: Boolean,
      default: false,
    },
    kit: {
      type: Object,
      default: () => {},
    },
    projectSubscriptionBlend: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editPlatforms: false,
      editCredit: false,
      showError: false,
    };
  },
  computed: {
    ...mapGetters('contentKit', ['socialTags']),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject']),
    name() {
      const title = this.$route.name === 'Content History'
        || this.$route.name === 'Content Plan History' ? 'SUBSCRIPTION SUMMARY' : 'ORDER SUMMARY';
      return this.fromBusiness
        ? 'SUBSCRIPTION SUMMARY'
        : title;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    getBlendAccess() {
      return _.get(this.projectSubscriptionBlend, 'subscriptionBlend.access');
    },
    platforms() {
      if (this.contentKitId) {
        return this.kit.platformSuggestions;
      }
      return this.projectSubscriptionBlend.socialTags;
    },
    subscription() {
      const project = _.get(this.kit, 'project', {});
      return project.business.subscriptionBlends > 0 ? project.business.subscriptionBlends[0] : {};
    },
    path() {
      if (this.$route.name === 'My Content Plan' || this.$route.name === 'New Content Plan') {
        return true;
      }
      return false;
    },
    socialTagNames() {
      return _.compact(_.map(this.platforms, (tag) => tag.name.trim())).join(', ');
    },
    canUpdate() {
      return _.includes(['admin', 'producer', 'executive'], this.role);
    },
    isEditable() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return this.canUpdate && assigned && this.currentProject.status !== '2';
    },
    contentKitId() {
      return this.$route.params.contentKitId || (this.kit && this.kit.id);
    },
  },
  methods: {
    ...mapActions('contentKit', ['getSocialTags']),
  },
  async mounted() {
    this.getSocialTags();
  },
};
</script>

<style lang="scss" scoped>
.b-1 {
  border: solid 1px #d8d8d8;
}
::v-deep .v-card:not(.v-card--flat):not(.v-picker--time):not(.v-picker--date) {
  border-radius: 3px !important;
}
.input {
  ::v-deep {
    .v-icon.v-icon.v-icon--link {
      padding-bottom: 16px !important;
    }
  }
}
::v-deep {
  .v-icon.v-icon.v-icon--link {
    padding-bottom: 0px !important;
  }
}
.v-list-item--two-line {
  min-height: 30px;
}
.input-box {
  ::v-deep .v-input__control {
    flex-grow: 0;
    width: 40%;
  }
}
.order-summary {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
}
.name-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
}
.save-btn {
  color: $secondary1;
  font-size: 14px;
}
.cancel-btn {
  font-size: 14px;
}
.more-text {
  color: $primary1;
  cursor: pointer;
  font-size: 16px;
}
.card-title {
  color: #727272 !important;
  font-size: 12px !important;
  font-family: $fontFamily1 !important;
  font-weight: bold !important;
}
.card-content-title {
  color: #1b1c1e !important;
  font-size: 24px !important;
}
.bg-white {
  background-color: $neutral6;
}
.bio-text {
  color: #1b1c1e !important;
  font-family: $fontFamily1;
}
.order-text {
  color: #1b1c1e !important;
  font-family: $fontFamily1;
  font-size: 14px;
}
.credits-text {
  font-family: $fontFamily1;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #787878;
}
.order-title {
  color: #727272;
  font-size: 14px !important;
  font-family: $fontFamily1;
}
.plan-text {
  color: #1b1c1e !important;
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 600 !important;
}
.suggest-title {
  color: $neutral7;
  font-size: 14px;
  font-family: $fontFamily1;
}
.suggest-text {
  color: $charcoalBlack !important;
  font-size: 14px;
  font-family: $fontFamily1;
}
::v-deep .v-btn > .v-btn__content .v-icon {
  width: 16px;
}
::v-deep .v-input__control {
  flex-grow: 0;
  width: 70%;
}
::v-deep .v-text-field--outlined .v-label {
  top: 10px;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot{
  border-color: $neutral10 !important;
  margin: 0 0 3px 0;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  color: $charcoalBlack !important;
  font-size: 15px;
  font-family: $fontFamily1;
}
.description {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
  color: #1b1c1e;
}
@media screen and (min-width: 801px) and (max-width: 1200px) {
  .input-box {
    ::v-deep .v-input__control {
      flex-grow: 0;
      width: 48%;
    }
  }
  ::v-deep .v-input__control {
      flex-grow: 0;
      width: 90%;
  }
}
@media screen and (min-width: 601px) and (max-width: 800px) {
  .input-box {
    ::v-deep .v-input__control {
      flex-grow: 0;
      width: 65%;
    }
  }
}
@media (max-width: 600px) {
  .p-1 {
   padding-left: 10px !important;
  }
  .mobile-pl-2 {
    padding-left: 8px !important;
  }
  .input-box {
    ::v-deep .v-input__control {
      flex-grow: 0;
      width: 100%;
    }
  }
  ::v-deep .v-input__control {
      flex-grow: 0;
      width: 90%;
    }
}
</style>

<template>
  <div class="bg-white project-overview-container h-100">
    <div class="main-container h-100">
      <Loader v-if="loading" />
      <div class="type-selection-wrapper" v-if="!loading && page !== 'org/business'">
        <v-select
          style="width:250px;"
          class="type-selection mb-n3 notranslate"
          dense
          solo
          flat
          :items="workspaceList"
          v-model="selectedWorkspaceId"
          append-icon="mdi-chevron-down"
          label="Select"
        />
      </div>
      <v-row dense class="ma-0 pa-0 mobile-padding" v-if="!loading">
        <v-col cols="12" class="pa-0">
          <div v-if="creativeBreifAccess && !['organization', 'org/business'].includes(this.page)">
            <span class="d-flex card-title pa-0 edit-height">
              <v-btn text @click="() => goToOverview()" class="pl-0 mb-1 sweeps-back-btn">
                <v-icon> mdi-chevron-left </v-icon>
                <span class="navigation-text">Back to Creative Brief</span>
              </v-btn>
            </span>
          </div>
          <v-col class="pa-0 pt-2 px-1">
            <OrderSummary :projectSubscriptionBlend="projectSubscriptionBlend" />
          </v-col>
          <v-col class="py-4 px-1">
            <v-card class="b-1 order-summary pa-0" elevation="0">
              <v-row class="ma-0">
                <v-col cols="9" class="pa-0">
                  <v-card-title class="card-title pb-0 pl-5">CONTENT PLANS</v-card-title>
                </v-col>
                <template v-if="isEditable">
                  <v-col cols="3" class="pa-0 pr-2 pt-6 d-flex justify-end" v-if="enableNew()">
                    <v-btn text @click="openForm" class="my-content-plan btn-purple pa-2">
                      + New
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
              <v-card-subtitle class="pa-4 pt-0 px-6" v-if="userContentKits.length">
                <v-simple-table fixed-header height="265px" class="table-height">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          v-for="item in headers"
                          :key="item.name"
                          class="pa-1 px-4"
                          :class="tableStyle(item.title)"
                        >
                          <span class="name-ellipsis">{{ item.title }}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in orderedContentKits" :key="item.name">
                        <td class="text-left pa-1 px-4 cursor-pointer" @click="onClick(item)">
                          <v-hover v-slot="{ hover }">
                            <div class="d-flex" :class="{ 'on-hover': hover }">
                              <span class="name-ellipsis pt-0 notranslate"> {{ item.name }}</span>
                              <span class="text-info pl-2">
                                {{ item.isDraft ? '(draft)' : '' }}
                              </span>
                            </div>
                          </v-hover>
                        </td>
                        <td class="text-left pa-1 px-4 cursor-pointer" @click="onClick(item)">
                          {{ createdDate(item.date) }}
                        </td>
                        <td class="text-center pa-1 px-4 cursor-pointer" @click="onClick(item)">
                          {{ item.totalCredits === null ? 0 : item.totalCredits }}
                        </td>
                        <td class="text-center pa-1 px-4 cursor-pointer" @click="onClick(item)">
                          {{ item.extraCredits }}
                        </td>
                        <td class="text-left pa-1 px-4">
                          <div v-if="isEditable">
                            <v-select
                              v-if="role === 'producer' || role === 'executive'"
                              class="status-select notranslate"
                              :items="planStatuses"
                              solo
                              flat
                              dense
                              item-value="value"
                              item-text="label"
                              :hide-details="true"
                              v-model="item.planStatus"
                              @change="updateContentPlanStatus($event, item.id)"
                              :menu-props="{ 'content-class': 'skillset-dropdown notranslate' }"
                              :disabled="isEditable ? false : true"
                            >
                            </v-select>
                          </div>
                          <div v-else>
                            {{ planStatus(item.planStatus) }}
                          </div>
                        </td>
                        <td class="text-left pa-1 px-4">
                          <v-btn @click="totalCredit(item)" small text>
                            <img
                              class="d-flex align-center pt-2 mt-n2 download-icon"
                              src="@/assets/svg/theme/cloud-computing.svg"
                              width="15"
                              height="22"
                            />
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-subtitle>
              <v-card-subtitle v-else>
                <span class="d-flex justify-center no-plans">No Content Plans</span>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <v-container fluid v-if="showContentplanForm">
        <Modal persistent content-class="new-user-form" :modal="showContentplanForm" scrollable>
          <ContentPlanForm
            @close-modal="closeForm"
            :projectSubscriptionBlend="projectSubscriptionBlend"
            :projectId="Number(selectedWorkspaceId)"
          />
        </Modal>
      </v-container>
      <template>
        <v-snackbar
          v-model="totalCreditCheck"
          color="#ff0808"
          multi-line
          top
          right
          :timeout="timeout"
        >
          Could not download this Content Plan. Please contact Blended Sense team for more details.
          <v-icon dark right @click="totalCreditCheck = false">
            mdi-close-circle
          </v-icon>
        </v-snackbar>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Modal from '@/components/common/Modal';
import ContentPlanForm from '@/components/dashboard/screens/common/ContentPlanForm';
import OrderSummary from '@/components/dashboard/screens/common/OrderSummary';
import Loader from '@/components/common/Loader';

export default {
  name: 'PlanHistory',
  components: {
    OrderSummary,
    Modal,
    ContentPlanForm,
    Loader,
  },
  props: {
    page: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('contentKit', ['userContentKits', 'projectSubscriptionBlend']),
    ...mapGetters('project', ['currentProject']),
    creativeBreifAccess() {
      return !['client', 'member', 'agency_owner', 'agency_member'].includes(this.role);
    },
    tableStyle() {
      return (style) => {
        /* eslint-disable */
        switch (style) {
          case 'Total Credits':
          case 'Extra Credits':
            return 'header-credits';
          case 'Create Date':
            return 'header-created';
          default:
            return 'text-left';
        }
      };
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    agency_customer() {
      return ['agency_owner', 'agency_member'].includes(this.role);
    },
    createdDate() {
      return (date) => moment(date).format('MM/DD/YYYY');
    },
    planStatus() {
      return (status) => {
        if (status === '1') {
          return 'Incomplete';
        }
        if (status === '2') {
          return 'In Progress';
        }
        return 'Complete';
      };
    },
    orderedContentKits() {
      return _.sortBy(this.userContentKits, (o) => moment(o.date)).reverse();
    },
    canUpdate() {
      return _.includes(['producer', 'executive'], this.role);
    },
    isEditable() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return this.canUpdate && assigned && this.currentProject.status !== '2';
    },
    deliverablesStatus() {
      return (status) => (status === '1' ? 'In Progress' : 'Completed');
    },
    creditQuantity() {
      return (cost, quantity) => cost * quantity;
    },
  },
  methods: {
    ...mapActions('contentKit', ['getContentKit', 'getUserContentKits', 'updateContentKit']),
    ...mapActions('business', ['loadBusinessWorkspaces']),
    ...mapActions('project', ['getProject']),
    enableNew() {
      const { isAssigned } = this.selectedWorkspace;
      return ['producer', 'executive'].includes(this.role) && isAssigned;
    },
    goToOverview() {
      this.$router.push(
        `/project_overview/${this.selectedWorkspaceId}?businessId=${this.businessId}`,
      );
    },
    onClick(item) {
      if (['organization', 'org/business'].includes(this.page)) {
        this.$emit('step-change', 2);
        if (['organization'].includes(this.page)) {
          this.$router.push({
            name: 'Organization',
            query: { contentKitId: item.id, projectId: item.projectId },
          });
        }
        if (['org/business'].includes(this.page)) {
          const { agencyName, businessName } = this.$route.query;
          this.$router.push({
            name: 'Business',
            query: { agencyName, businessName, contentKitId: item.id, projectId: item.projectId },
          });
        }
      } else {
        if (!['client', 'member', 'agency_owner', 'agency_member'].includes(this.role)) {
          this.$router.push(
            `/project_overview/${this.selectedWorkspaceId}/content_plan/${item.id}?businessId=${
              this.businessId
            }`,
          );
        }
        this.$router.push(
          `/content_plan_history/${this.selectedWorkspaceId}/content_plan/${item.id}?businessId=${
            this.businessId
          }`,
        );
      }
    },
    updateContentPlanStatus(e, kitId) {
      this.updateContentKit({ id: kitId, payload: { planStatus: e } });
    },
    openForm() {
      this.showContentplanForm = true;
    },
    totalCredit(item) {
      if (item.totalCredits === null || item.totalCredits === 0) {
        this.totalCreditCheck = true;
      } else {
        this.createPDF(item);
      }
    },
    closeForm() {
      this.showContentplanForm = false;
    },

    deliverableArray(data) {
      const result = [];
      for (let i = 0; i < data.length; i += 1) {
        result.push([
          data[i].deliverable.name,
          _.get(data[i].deliverable.category, 'name'),
          _.map(data[i].dimensions, 'dimension.name').join(', ') || ' ',
          `${data[i].quantity}`,
          this.deliverablesStatus(data[i].status),
          `${data[i].deliverable.creditCost * data[i].quantity}`,
        ]);
      }
      return result;
    },
    async createPDF(item) {
      const result = await this.getContentKit(item.id);
      let kit = {};
      if (result.success) {
        kit = result.contentKit;
      }
      const headers = [
        'Deliverable',
        'Deliverable Type',
        'Dimensions',
        'Quantity',
        'Status',
        'Credit Cost',
      ];
      const pdfName = `${kit.name} content plan`;
      let y = 0;
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({ orientation: 'landscape' });
      doc.setFontSize(12);
      doc.text(kit.name, 25, (y += 20));
      doc.setFontSize(11);
      if (kit.description) {
        doc.text(doc.splitTextToSize(kit.description, 250), 25, 28);
        y = 40;
      }
      doc.rect(25, y + 3, 250, 53);
      doc.setTextColor(75);
      doc.text('ORDER SUMMARY', 30, (y += 10));

      doc.setTextColor(100);
      doc.text('Subscription:', 35, y + 8);
      doc.text('Suggested Platforms:', 150, (y += 8));
      doc.setTextColor(0);
      doc.text(_.get(kit.subscriptionBlend, 'name', ' '), 35, y + 6);
      doc.text(
        doc.splitTextToSize(_.map(kit.platformSuggestions, 'name').join(', '), 120),
        150,
        (y += 6),
      );
      doc.setTextColor(100);
      doc.text('Your current capture is:', 35, (y += 8));
      doc.setTextColor(0);
      doc.text(_.get(kit.subscriptionBlend, 'access', ' '), 35, (y += 6));

      doc.setTextColor(100);
      doc.text('Production credits:', 35, (y += 8));
      doc.setTextColor(0);
      doc.text(`${kit.totalCredits}/content kit`, 35, (y += 6));
      doc.setTextColor(100);
      if (kit.extraCredits) {
        doc.text(`+ ${kit.extraCredits} extra credits`, 65, y);
      }

      doc.setTextColor(75);
      doc.text('DELIVERABLES', 25, (y += 15));
      doc.setTextColor(0);
      if (kit.projectDeliverables.length) {
        doc.autoTable({
          head: [headers],
          body: this.deliverableArray(kit.projectDeliverables),
          startY: (y += 4),
          margin: { left: 25 },
          tableWidth: 250,
          styles: {
            fontSize: 11,
            cellPadding: 3,
            lineColor: 10,
            lineWidth: 0.2,
          },
          headStyles: { fillColor: [144, 144, 144] },
        });
        // doc.fromHTML(this.quotePdf(), 10, 100);
      } else {
        doc.setTextColor(0).text('No deliverables', 30, (y += 10));
      }
      doc.save(`${pdfName}.pdf`);
    },
  },
  data() {
    return {
      businessId: null,
      timeout: 6000,
      loading: true,
      showContentplanForm: false,
      totalCreditCheck: false,
      headers: [
        {
          title: 'Name',
          value: 'name',
        },
        {
          title: 'Create Date',
          value: 'createdDate',
        },
        {
          title: 'Total Credits',
          value: 'totalCredits',
        },
        {
          title: 'Extra Credits',
          value: 'extraCredits',
        },
        {
          title: 'Status',
          value: 'status',
        },
        {
          title: '',
          value: 'download',
        },
      ],
      planStatuses: [
        { label: 'Incomplete', value: '1' },
        { label: 'In Progress', value: '2' },
        { label: 'Complete', value: '3' },
      ],
      selectedWorkspaceId: null,
      selectedWorkspace: {},
      businessWorkSpaces: [],
      workspaceList: [],
    };
  },
  watch: {
    async selectedWorkspaceId(newVal) {
      if (newVal) {
        this.loading = true;
        await this.getProject(newVal);
        await this.getUserContentKits({
          projectId: newVal,
          isPlans: true,
        });
        this.selectedWorkspace = _.find(this.businessWorkSpaces, ['id', newVal]);
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    const { businessId } = this.$route.query;
    this.businessId = businessId;
    if (['organization'].includes(this.page)) {
      this.businessId = this.$route.params.organizationId;
    }
    if (['org/business'].includes(this.page)) {
      this.businessId = this.$route.params.businessId;
    }
    // fix for the issue of the route not being updated (fix routing in future)
    if (!this.businessId) {
      // get businessId from projectId
      const { projectId } = this.$route.params;
      const { project } = await this.getProject(projectId);
      this.businessId = project.businessId;
    }
    const { projects, entityType } = await this.loadBusinessWorkspaces({
      businessId: this.businessId,
    });
    this.businessWorkSpaces = projects;
    const workspaceList = _.map(projects, (project) => {
      let displayName = null;
      if (entityType === 'organization') {
        displayName = project.name;
      } else {
        displayName = project.business.name;
      }
      return {
        // eslint-disable-next-line
        text: `${displayName}`,
        value: project.id,
      };
    });
    this.selectedWorkspaceId = await _.get(workspaceList, '[0]').value;
    this.selectedWorkspace = _.find(projects, ['id', this.selectedWorkspaceId]);
    this.workspaceList = workspaceList;
    await this.getUserContentKits({
      projectId: this.selectedWorkspaceId,
      isPlans: true,
    });
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.sweeps-back-btn {
  padding: 1.5rem 1rem 1.5rem 0.2rem !important;
  &::before {
    color: unset !important;
    background: none !important;
  }
  &:hover {
    background: #f7f7f7 !important;
  }
}
.bg-white {
  background-color: $neutral6;
  height: 100%;
}
.order-summary {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
}
.card-title {
  color: #727272 !important;
  font-size: 12px !important;
  font-family: $fontFamily1 !important;
  font-weight: bold !important;
}
.b-1 {
  border: solid 1px #d8d8d8;
}
.navigation-text {
  color: #727272;
  font-size: 14px !important;
  font-family: $fontFamily1;
  font-weight: bold;
  text-align: left;
  padding-left: 5px;
}
::v-deep .v-card:not(.v-card--flat):not(.v-picker--time):not(.v-picker--date) {
  border-radius: 3px !important;
}
.my-content-plan {
  font-family: $fontFamily1;
  font-weight: normal;
  text-align: left;
  ::v-deep .v-btn__content {
    font-size: 14px;
    line-height: 1.38;
    letter-spacing: -0.38px;
  }
}
::v-deep .v-btn > .v-btn__content .v-icon {
  width: 15px;
}
::v-deep {
  .v-select__selection--comma {
    font-size: 14px;
    font-family: $fontFamily1;
    font-weight: normal;
    line-height: 1.4;
    text-align: left;
    color: #1b1c1e;
  }
}
.v-data-table--fixed-header thead th {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
  color: #727272;
  width: 25%;
  border-bottom: 1px solid $neutral7 !important;
}
.v-data-table td {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
  color: #1b1c1e;
}
.table-height {
  max-height: 300px;
  overflow: auto;
}
.name-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
::v-deep {
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding: 0 4px;
  }
}
.on-hover {
  color: #00b2d5;
}
.cursor {
  cursor: pointer;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 24px;
}
.no-plans {
  font-size: 14px;
  font-family: $fontFamily1;
  font-weight: normal;
  color: #727272;
  line-height: 1.66;
  letter-spacing: normal;
}
.header-created {
  width: 15% !important;
}
.header-credits {
  text-align: center !important;
  width: 20% !important;
}
.download-icon {
  cursor: pointer;
}
.text-info {
  color: $primary1;
}
.status-select {
  width: 80%;
}
@media (max-width: 1300px) {
  .status-select {
    width: 90% !important;
  }
}
@media (max-width: 600px) {
  .mobile-padding {
    padding-top: 24px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}
.type-selection-wrapper {
  width: 250px;
  border: 1px solid #d8d8d8;
  height: 40px;
  margin-bottom: 1rem;
}
</style>
